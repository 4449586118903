@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Mansalva&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

.rdp {
      display: flex;
      align-items: center;
      justify-content: center;
}

.rdp-months {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 5px;
}

.rdp-caption {
      justify-content: center !important;
}

.rdp-nav {
      display: none;
}

.rdp-month {
      border-radius: 5px;
      padding: 10px;
      background-color: white;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
      background-color: #224082 !important;
      color: var(--rdp-selected-color);
      opacity: 1;
}

.spinner-border {
      height: 1.5rem !important;
      width: 1.5rem !important;
      border-width: 2px !important;
}

.modal-fullscreen .modal-content {
      margin-top: 0;
}
