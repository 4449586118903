body {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
            'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: relative;
}

code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
}
.btn-close:focus {
      outline: 0;
      box-shadow: none !important;
      opacity: var(--bs-btn-close-focus-opacity);
}
.modal-content {
      margin-top: 130px;
}
